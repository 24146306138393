import {} from "@/assets/icons";
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui";
import { cn, upperFirst } from "@/lib/utils";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { IconProps } from "@radix-ui/react-icons/dist/types";
import { ForwardRefExoticComponent, RefAttributes, useCallback } from "react";
import { useProductTour } from "../../../productTourContext/useProductTour";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsDemo, useOnFirstLogin } from "@/hooks";
import { RouteEnum } from "@/types";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export interface ProductTourButtonProps {
  isExpanded: boolean;
  Icon?: ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>;
  label?: string;
}
export function ProductTourButton({
  isExpanded,
  Icon = QuestionMarkCircledIcon,
  label,
}: ProductTourButtonProps) {
  const { t } = useTranslation();
  const { setJoyrideState } = useProductTour();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDemo = useIsDemo();

  const startJoyride = useCallback(() => {
    if (pathname !== (RouteEnum.DASHBOARD as string)) {
      navigate(`${RouteEnum.DASHBOARD}${isDemo ? "?demo=true" : ""}`);
    }
    setJoyrideState({ run: true, tourActive: true, stepIndex: 0 });
  }, [isDemo, navigate, pathname, setJoyrideState]);

  useOnFirstLogin(startJoyride);

  const button = (
    <Button
      variant="text"
      className="jr-start-pg-dashboard flex h-9 items-center gap-x-5 px-3 text-sm text-white/60 hover:text-white"
      onClick={startJoyride}
    >
      <motion.div layout>
        <Icon className="h-6 w-6 shrink-0" />
      </motion.div>
      <span
        className={cn(
          {
            "animate-fade-in pr-4 font-light": isExpanded,
          },
          { "sr-only": !isExpanded },
        )}
      >
        {label ?? upperFirst(t("common_productTour"))}
      </span>
    </Button>
  );
  return (
    <>
      {isExpanded ? (
        button
      ) : (
        <Tooltip>
          <TooltipTrigger asChild>{button}</TooltipTrigger>
          <TooltipContent
            className="bg-foreground text-background"
            side="right"
          >
            {label ?? t("common_productTour")}
          </TooltipContent>
        </Tooltip>
      )}
    </>
  );
}
