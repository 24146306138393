import {} from "@/assets/icons";
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui";
import { cn, upperFirst } from "@/lib/utils";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { IconProps } from "@radix-ui/react-icons/dist/types";
import { ForwardRefExoticComponent, RefAttributes } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/api/auth";
import clsx from "clsx";
import { ButtonVariants } from "@/components/ui";

export interface LiveChatNavButtonProps {
  isExpanded: boolean;
  Icon?: ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>;
  label?: string;
  variant?: ButtonVariants;
}
export function LiveChatNavButton({
  isExpanded,
  Icon = ChatBubbleIcon,
  variant,
  label,
}: LiveChatNavButtonProps) {
  const { t } = useTranslation();
  const {
    keycloak: { subject },
    profile: { username, firstName, lastName } = {},
  } = useAuth();

  const handleClick = () => {
    if (subject && username && firstName && lastName) {
      window.$chatwoot.setUser(subject, {
        email: username,
        name: clsx(firstName, lastName),
      });
    }
    window.$chatwoot.toggle();
  };

  const button = (
    <Button
      variant={variant || "text"}
      className="flex h-9 items-center gap-x-5 px-3 text-sm text-white/60 hover:text-white"
      onClick={handleClick}
    >
      <motion.div layout>
        <Icon className="h-6 w-6 shrink-0" />
      </motion.div>
      <span
        className={cn(
          {
            "animate-fade-in pr-4 font-light": isExpanded,
          },
          { "sr-only": !isExpanded },
        )}
      >
        {label ?? upperFirst(t("common_liveChat"))}
      </span>
    </Button>
  );
  return (
    <>
      {isExpanded ? (
        button
      ) : (
        <Tooltip>
          <TooltipTrigger asChild>{button}</TooltipTrigger>
          <TooltipContent
            className="bg-foreground text-background"
            side="right"
          >
            {label ?? t("common_liveChat")}
          </TooltipContent>
        </Tooltip>
      )}
    </>
  );
}
