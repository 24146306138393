import { z } from "zod";
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  Switch,
  DialogClose,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCookieConsent } from "./useCookieConsent";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { CookieTable } from "./CookieTable";
import { cookiesData } from "./cookiesData";

const preferencesFormSchema = z.object({
  necessary: z.boolean().default(true),
  functional: z.boolean(),
  analytics: z.boolean(),
});

export function PrefrencesForm({ isDialog = false }: { isDialog?: boolean }) {
  const {
    cookieConsent,
    // acceptFunctional,
    // rejectFunctional,
    acceptAnalytics,
    rejectAnalytics,
    acceptAllCookies,
    closeBanner,
  } = useCookieConsent();

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    acceptAllCookies();
    closeBanner();
  }

  function onSavePreferences() {
    closeBanner();
  }

  const initialValues = {
    necessary: true,
    functional: cookieConsent.functional,
    analytics: cookieConsent.analytics,
  };

  const form = useForm<z.infer<typeof preferencesFormSchema>>({
    resolver: zodResolver(preferencesFormSchema),
    defaultValues: initialValues,
  });

  const { /* functional, */ analytics } = cookiesData;

  return (
    <Form {...form}>
      <form onSubmit={onSubmit} className="w-full space-y-6">
        <div>
          <div className="space-y-4 pt-6">
            <FormField
              control={form.control}
              name="necessary"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start justify-between gap-2 rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">
                      Essential Cookies (always active)
                    </FormLabel>
                    <FormDescription className=" pr-2">
                      Necessary cookies are required to enable the basic
                      features of this site, such as providing secure log-in or
                      adjusting your consent preferences. These cookies do not
                      store any personally identifiable data.
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      disabled
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            {/* <FormField
              control={form.control}
              name="functional"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start justify-between gap-2 rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">
                      Functional Cookies
                    </FormLabel>
                    <FormDescription className="pr-2">
                      These cookies are used to make the Website work more
                      efficiently and to remember the choices you make while
                      browsing. For example, these cookies are required for the
                      live chat feature to work.
                    </FormDescription>
                    <Collapsible className="pt-2">
                      <CollapsibleTrigger className="text-sm">
                        <span>More info</span>
                        <ChevronDownIcon className="inline-block size-4" />
                      </CollapsibleTrigger>
                      <CollapsibleContent className="rounded-md bg-accent text-sm">
                        <CookieTable cookies={functional} />
                      </CollapsibleContent>
                    </Collapsible>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked);
                        if (checked) {
                          acceptFunctional();
                        } else {
                          rejectFunctional();
                        }
                        if (!isDialog) closeBanner();
                      }}
                      aria-readonly
                    />
                  </FormControl>
                </FormItem>
              )}
            /> */}
            <FormField
              control={form.control}
              name="analytics"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start justify-between gap-2 rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">
                      Posthog Analytics
                    </FormLabel>
                    <FormDescription className="pr-2">
                      Analytical cookies are used to understand how visitors
                      interact with the application. These cookies help provide
                      information on metrics such as the number of visitors,
                      bounce rate, traffic source, etc.
                    </FormDescription>
                    <Collapsible className="pt-2">
                      <CollapsibleTrigger className="text-sm">
                        <span>More info</span>
                        <ChevronDownIcon className="inline-block size-4" />
                      </CollapsibleTrigger>
                      <CollapsibleContent className="rounded-md bg-accent text-sm">
                        <CookieTable cookies={analytics} />
                      </CollapsibleContent>
                    </Collapsible>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked);
                        if (checked) {
                          acceptAnalytics();
                        } else {
                          rejectAnalytics();
                        }
                        if (!isDialog) closeBanner();
                      }}
                      aria-readonly
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
        {isDialog && (
          <div className="flex justify-end gap-2">
            <DialogClose asChild>
              <Button type="submit">Accept all</Button>
            </DialogClose>
            <DialogClose asChild>
              <Button variant="outline" onClick={onSavePreferences}>
                Save My Preferences
              </Button>
            </DialogClose>
          </div>
        )}
      </form>
    </Form>
  );
}
