import { Outlet } from "react-router-dom";
import { Header, DesktopNavBar, MobileNav } from "./components";
import { Suspense, useCallback, useState } from "react";
import { Loader } from "@/assets/Loader";
import { cn } from "@/lib/utils";
import { NxtLogLogo } from "@/assets/NxtLogLogo";
import { UserNav } from "./components/UserNav";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "@/components/shared/ErrorFallback";
import { ProductTourProvider } from "./productTourContext";
import { ProductTour } from "./components/LeftNavBar/ProductTour/ProductTour";
import { DateRangeContextProvider2 } from "./dateRangeContext/DateRangeContextProvider2";
import { motion } from "framer-motion";
import {
  useRefetchOnDataImport,
  useRefetchOnStatusUpdate,
  useUpdateProcessingStatus,
} from "@/hooks";
import { useMediaQuery } from "usehooks-ts";

export const NavigationLayout = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const isXl = useMediaQuery("(min-width: 1280px)");
  useUpdateProcessingStatus();
  useRefetchOnDataImport();
  useRefetchOnStatusUpdate();

  const handleExpand = useCallback(() => {
    setIsExpanded((currrent) => !currrent);
  }, []);

  const isNavBarOpen = isExpanded && isXl;

  return (
    <div
      className={cn(
        { "md:grid-cols-[auto_1fr]": isNavBarOpen },
        { "md:grid-cols-[53px_1fr]": !isNavBarOpen },
        { "grid-rows-[64px_1fr] md:grid-rows-1": !isDesktop },
        "grid h-screen",
      )}
    >
      {!isDesktop && (
        <Header className="flex h-16 shrink-0 flex-row items-center justify-between border-b bg-white px-2 dark:bg-black md:col-span-2">
          <MobileNav />
          <NxtLogLogo className="h-10 text-foreground" />
          <UserNav />
        </Header>
      )}
      <ProductTourProvider>
        <DesktopNavBar
          className="hidden h-[calc(100vh)] justify-between overflow-hidden bg-black px-0.5 pb-4 md:flex md:flex-col"
          handleExpand={handleExpand}
          isXl={isXl}
          isExpanded={isNavBarOpen}
        />
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <div className="row-start-2 h-full w-full md:col-start-2 md:row-start-1">
              <ErrorFallback
                /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                error={error}
                resetErrorBoundary={resetErrorBoundary}
              />
            </div>
          )}
        >
          <Suspense
            fallback={
              <div className="flex h-screen w-full items-center justify-center">
                <Loader className="h-1/4" />
              </div>
            }
          >
            <DateRangeContextProvider2>
              <motion.div
                layout
                className="h-[calc(100vh)] bg-black pl-0 md:col-start-2 md:p-2"
              >
                <div className="h-full overflow-hidden bg-gray-50 dark:bg-zinc-900 md:rounded-2xl">
                  <Outlet />
                </div>
              </motion.div>
            </DateRangeContextProvider2>
          </Suspense>
        </ErrorBoundary>
        <ProductTour />
      </ProductTourProvider>
    </div>
  );
};
