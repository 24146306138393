import { BaseIcon, IconProps } from ".";

export function SolarDocumentTextLinear(props: IconProps) {
  return (
    <BaseIcon {...props}>
      <g fill="none" stroke="currentColor" strokeWidth="1.5">
        <path d="M3 10c0-3.771 0-5.657 1.172-6.828C5.343 2 7.229 2 11 2h2c3.771 0 5.657 0 6.828 1.172C21 4.343 21 6.229 21 10v4c0 3.771 0 5.657-1.172 6.828C18.657 22 16.771 22 13 22h-2c-3.771 0-5.657 0-6.828-1.172C3 19.657 3 17.771 3 14v-4Z"></path>
        <path strokeLinecap="round" d="M8 12h8M8 8h8m-8 8h5"></path>
      </g>
    </BaseIcon>
  );
}
