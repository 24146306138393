import { useForm } from "react-hook-form";
import { createAccountFormSchema } from "./createAccountFormSchema";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
  Input,
  Separator,
  buttonVariants,
} from "@/components/ui";
import { LoginLink } from "./LoginLink";
import { useRegisterUser } from "@/hooks/mutations";
import { TermsAndConditionsDialog } from "../../TermsAndConditions/components/TermsAndConditionsDialog";
import { EyeNoneIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "@/api/auth";
import { Loader } from "@/assets/Loader";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils";

export function CreateAccountForm() {
  const { t } = useTranslation();
  const { keycloak } = useAuth();
  const { subject } = keycloak;
  const { mutate, isSuccess, isPending } = useRegisterUser();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const form = useForm<z.infer<typeof createAccountFormSchema>>({
    resolver: zodResolver(createAccountFormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      termsAndConditions: false,
    },
    mode: "onSubmit",
  });

  if (subject) {
    return <Navigate to="/" />;
  }

  const onSubmit = ({
    confirmPassword: _c,
    termsAndConditions: _t,
    ...data
  }: z.infer<typeof createAccountFormSchema>) => {
    mutate({ data });
  };

  const formView = (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("common_firstName")}</FormLabel>
                <FormControl>
                  <Input
                    autoFocus
                    placeholder={t("common_firstName")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("common_lastName")}</FormLabel>
                <FormControl>
                  <Input placeholder={t("common_lastName")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>{t("common_email")}</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    placeholder={t("common_email")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>{t("common_password")}</FormLabel>
                <FormControl>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder={t("common_password")}
                    suffix={
                      showPassword ? (
                        <EyeNoneIcon
                          role="button"
                          onClick={() => setShowPassword(false)}
                          className="h-6 w-6 text-gray-400"
                        />
                      ) : (
                        <EyeOpenIcon
                          role="button"
                          onClick={() => setShowPassword(true)}
                          className="h-6 w-6 text-gray-400"
                        />
                      )
                    }
                    {...field}
                  />
                </FormControl>
                <FormDescription className="text-2xs">
                  {t("descriptions_passwordPolicy")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>{t("common_confirmPassword")}</FormLabel>
                <FormControl>
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder={t("common_confirmPassword")}
                    suffix={
                      showConfirmPassword ? (
                        <EyeNoneIcon
                          role="button"
                          onClick={() => setShowConfirmPassword(false)}
                          className="h-6 w-6 text-gray-400"
                        />
                      ) : (
                        <EyeOpenIcon
                          role="button"
                          onClick={() => setShowConfirmPassword(true)}
                          className="h-6 w-6 text-gray-400"
                        />
                      )
                    }
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div>
          <FormField
            control={form.control}
            name="termsAndConditions"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <div className="flex items-center gap-x-3">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="font-light">
                    {t("common_iAgreeToThe")}{" "}
                    <a
                      className="text-primary hover:underline"
                      // href="#"
                    >
                      <TermsAndConditionsDialog />
                    </a>
                  </FormLabel>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex w-full justify-center gap-3">
          <Link
            to="/"
            className={cn(
              { "pointer-events-none opacity-50": isPending },
              buttonVariants({ variant: "outline" }),
            )}
          >
            {t("common_cancel")}
          </Link>
          <Button isLoading={isPending} type="submit">
            {t("common_submit")}
          </Button>
        </div>
      </form>
    </Form>
  );

  if (isSuccess) {
    keycloak
      .login({ loginHint: form.getValues("email") })
      ?.then()
      .catch((e) => console.error(e));
  }

  // if (isPending || isSuccess) {
  if (isSuccess) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader className="h-1/4" />
      </div>
    );
  }

  return (
    <div className="m-auto space-y-3">
      <div className="flex flex-col gap-y-3">
        <h2 className="text-center text-lg">{t("common_createAccount")}</h2>
        <Separator />
      </div>
      <div>{formView}</div>
      {!isSuccess && (
        <div className="flex items-center justify-center font-light text-gray-600">
          Already have an accout? <LoginLink className="ml-1 px-0 text-base" />
        </div>
      )}
    </div>
  );
}
