import { ChevronDownIcon } from "@radix-ui/react-icons";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  // DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../ui";
import i18next from "i18next";
import { languages } from "@/i18next/languages";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export function LanguageSelector() {
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(
    () => i18next.resolvedLanguage || "en",
  );

  const changeLanguage = (language: string) => {
    i18next
      .changeLanguage(language)
      .then(() => {
        setCurrentLanguage(language);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="items-center space-x-3">
          <span className="text-2xl">
            {(languages[currentLanguage] ?? languages["en"]).flag}
          </span>
          <span className="text-md text-foreground/50">
            {(languages[currentLanguage] ?? languages["en"]).name}
          </span>
          <ChevronDownIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-26" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="inline-flex w-full items-center justify-center font-medium italic text-foreground/50">
            {t("common_selectLanguage")}
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {Object.values(languages)
            // .filter((lang) => lang.visible)
            .map((language) => (
              <DropdownMenuItem
                key={language.name}
                onClick={() => changeLanguage(language.code)}
              >
                <span>{language.flag}</span>
                <span className="ml-2">{language.name}</span>
              </DropdownMenuItem>
            ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
