import { BaseIcon, IconProps } from ".";

export function SolarReciveSquareLinear(props: IconProps) {
  return (
    <BaseIcon {...props}>
      <g fill="none" stroke="currentColor" strokeWidth="1.5">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7 12h7m0 0l-3 3m3-3l-3-3"
        ></path>
        <path strokeLinecap="round" d="M17 16V8"></path>
        <path d="M2 12c0-4.714 0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22s-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12Z"></path>
      </g>
    </BaseIcon>
  );
}
