import { IconProps } from "@/assets/icons";
import { MediumHeading, Subheading } from ".";
import { FileIcon } from "@radix-ui/react-icons";
import { forwardRef } from "react";

interface EmptyStateProps {
  Icon?: (props: IconProps) => JSX.Element;
  title: string;
  description?: string;
  action?: React.ReactNode;
}

export const EmptyState = forwardRef<HTMLDivElement, EmptyStateProps>(
  ({ title, description, action, Icon }, ref) => {
    return (
      <div ref={ref} className="flex w-full justify-center">
        <div className="relative flex w-1/2 justify-center bg-radial-lines-bg bg-center bg-no-repeat lg:w-1/3">
          {Icon ? (
            <Icon className="absolute inset-1/2 h-12 w-12 -translate-x-1/2 -translate-y-1/2 text-primary" />
          ) : (
            <FileIcon className="absolute inset-1/2 h-12 w-12 -translate-x-1/2 -translate-y-1/2 text-primary" />
          )}
          <div className="grid grid-rows-3">
            {/* <div className="row-start-2 flex items-center justify-center">
              {Icon ? (
                <Icon className="h-12 w-12 text-primary" />
              ) : (
                <FileIcon className="h-12 w-12 text-primary" />
              )}
            </div> */}
            <div className="row-start-3 flex min-h-40 justify-center">
              <div className="flex flex-col gap-6 pb-6">
                <div className="flex flex-col items-center">
                  <MediumHeading className="text-center !text-lg">
                    {title}
                  </MediumHeading>
                  {description && (
                    <Subheading className="text-center">
                      {description}
                    </Subheading>
                  )}
                </div>
                {!!action && (
                  <div className="flex flex-col items-center gap-2">
                    {action}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

EmptyState.displayName = "EmptyState";
