import useSetState from "@/hooks/useSetState";
import { createContext, useMemo } from "react";
import { Step } from "react-joyride";

interface JoyrideState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
}

export const ProductTourContext = createContext(
  {} as {
    joyrideState: JoyrideState;
    setJoyrideState: (
      patch:
        | Partial<JoyrideState>
        | ((prevState: JoyrideState) => Partial<JoyrideState>),
    ) => void;
  },
);

export function ProductTourProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [joyrideState, setJoyrideState] = useSetState<JoyrideState>({
    run: false,
    stepIndex: 0,
    steps: [],
    tourActive: false,
  });

  const contextValue = useMemo(
    () => ({
      joyrideState,
      setJoyrideState,
    }),
    [joyrideState, setJoyrideState],
  );

  return (
    <ProductTourContext.Provider value={contextValue}>
      {children}
    </ProductTourContext.Provider>
  );
}
