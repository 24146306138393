import { NxtLogLogo } from "@/assets/NxtLogLogo";
import { Link, Outlet } from "react-router-dom";
import { FooterLegalLinks } from "./components/FooterLegalLinks";
import { Boxes } from "../../ui";
import { createContext, createRef, Suspense } from "react";
import { LanguageSelector } from "@/components/shared";
import { useAuth } from "@/api/auth";
import { DashboardLink } from "@/components/pages/InitialUpload/components";
import { UserNav } from "../NavigationLayout/components/UserNav";
import { ThemeSwitch } from "../NavigationLayout/components";
import { Loader } from "@/assets/Loader";

export const BannerRefContext = createContext(createRef<HTMLDivElement>());

export const NoNavLayout = () => {
  const {
    keycloak: { authenticated },
  } = useAuth();
  const bannerContentRef = createRef<HTMLDivElement>();
  return (
    <div className="relative grid h-screen justify-center bg-gray-50 dark:bg-zinc-900 lg:max-w-none lg:grid-cols-3 lg:px-0 xl:grid-cols-12">
      <div className="relative col-span-1 hidden h-screen flex-col justify-between bg-black p-10 text-white dark:border-r lg:flex xl:col-span-3">
        <div className="z-10 flex items-center text-lg font-medium">
          <Link to="/">
            <NxtLogLogo className="h-12" />
          </Link>
        </div>
        <div
          ref={bannerContentRef}
          className="relative z-10 has-[#stepper]:pointer-events-none"
        >
          {/* Portal content here */}
        </div>
        <FooterLegalLinks />
        <div className="absolute left-0 top-0 z-0 h-full w-full overflow-hidden [mask-image:radial-gradient(white,transparent)]">
          <Boxes />
        </div>
      </div>
      <BannerRefContext.Provider value={bannerContentRef}>
        <Suspense
          fallback={
            <div className="col-span-3 flex h-screen w-full items-center justify-center lg:col-span-2 lg:p-8 xl:col-span-9">
              <Loader className="h-1/4" />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </BannerRefContext.Provider>
      <nav className="fixed right-0 top-0 z-20 flex items-center divide-x pb-0.5 pr-3 pt-0.5 lg:pt-3">
        {authenticated ? (
          <>
            <DashboardLink />
            <UserNav className="pl-3" />
          </>
        ) : (
          <>
            <LanguageSelector />
            <ThemeSwitch />
          </>
        )}
      </nav>
    </div>
  );
};
