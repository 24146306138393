import { BaseIcon, IconProps } from ".";

export function SolarSignpost2Linear(props: IconProps) {
  return (
    <BaseIcon {...props}>
      <g fill="none">
        <path
          fill="currentColor"
          d="M12.75 2a.75.75 0 0 0-1.5 0h1.5Zm-1.5 0v1h1.5V2h-1.5Zm1.5 9a.75.75 0 0 0-1.5 0h1.5Zm-1.5 0v1h1.5v-1h-1.5Zm1.5 9a.75.75 0 0 0-1.5 0h1.5Zm-1.5 0v2h1.5v-2h-1.5Z"
        ></path>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M14 22h-4M9 7h6m-6 9h6"
        ></path>
        <path
          stroke="currentColor"
          strokeWidth="1.5"
          d="M6.704 3.5H17.5c1.404 0 2.107 0 2.611.337a2 2 0 0 1 .552.552C21 4.893 21 5.596 21 7c0 1.404 0 2.107-.337 2.611a2 2 0 0 1-.552.552c-.504.337-1.207.337-2.611.337H6.704c-.658 0-.986 0-1.288-.098a1.998 1.998 0 0 1-.383-.17c-.274-.16-.494-.404-.933-.894c-.85-.947-1.276-1.42-1.379-1.974a2 2 0 0 1 0-.728c.103-.553.528-1.027 1.379-1.974c.44-.49.659-.734.933-.893a2 2 0 0 1 .383-.17c.302-.099.63-.099 1.288-.099Zm10.592 9H6.5c-1.404 0-2.107 0-2.611.337a2 2 0 0 0-.552.552C3 13.893 3 14.596 3 16c0 1.404 0 2.107.337 2.611a2 2 0 0 0 .552.552c.504.337 1.207.337 2.611.337h10.796c.658 0 .986 0 1.288-.098c.133-.044.261-.1.383-.17c.274-.16.494-.404.933-.894c.85-.947 1.276-1.42 1.379-1.974a2 2 0 0 0 0-.728c-.103-.553-.528-1.027-1.379-1.974c-.44-.49-.659-.734-.933-.893a1.996 1.996 0 0 0-.383-.17c-.302-.099-.63-.099-1.288-.099Z"
        ></path>
      </g>
    </BaseIcon>
  );
}
