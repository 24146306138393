import { motion } from "framer-motion";
import { LiveChatNavButton, NavItems } from ".";
import { ExpandButton } from "./ExpandButton";
import { ProductTourButton } from "./ProductTour/ProductTourButton";
import { UserNav } from "../UserNav";
import { Separator } from "@/components/ui";
import { Link } from "react-router-dom";
import { NxtLogLogo } from "@/assets/NxtLogLogo";
import { useIsDemo } from "@/hooks";

export function DesktopNavBar({
  className,
  isExpanded,
  handleExpand,
  isXl,
}: {
  className?: string;
  isExpanded: boolean;
  handleExpand: () => void;
  isXl?: boolean;
}) {
  const isDemo = useIsDemo();
  return (
    <motion.div layout className={className}>
      <div className="relative flex flex-col">
        {isExpanded && (
          <Link to={isDemo ? "/?demo=true" : "/"}>
            <NxtLogLogo className="absolute top-3 h-8 text-white" />
          </Link>
        )}
        <ExpandButton
          hide={!isXl}
          isExpanded={isExpanded}
          handleExpand={handleExpand}
        />
        <nav className="pt-[3.25rem]">
          <NavItems isExpanded={isExpanded} />
        </nav>
      </div>
      <div className="flex flex-col items-start">
        <ProductTourButton isExpanded={isExpanded} />
        <LiveChatNavButton isExpanded={isExpanded} />
        <Separator className="my-4 bg-zinc-700" />
        <div className="px-1">
          <UserNav />
        </div>
      </div>
    </motion.div>
  );
}
