import {
  DropdownMenu,
  DropdownMenuTrigger,
  Button,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuShortcut,
  Avatar,
  AvatarImage,
  AvatarFallback,
} from "@/components/ui";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { useAuth } from "@/api/auth";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { RouteEnum } from "@/types";
import { cn, getInitials } from "@/lib/utils";
import { ThemeSwitch } from "../ThemeSwitch";
import { useIsDemo } from "@/hooks";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "@/components/shared";
import { IS_DEV } from "@/lib/constants";
import { motion } from "framer-motion";

const VITE_APP_URL = import.meta.env.VITE_APP_URL;
const MotionAvatar = motion(Avatar);

export function UserNav({ className }: { className?: string }) {
  const { t } = useTranslation();
  const isDemo = useIsDemo();
  const { profile, keycloak } = useAuth();
  const logoutUrl = keycloak.createLogoutUrl({
    redirectUri: VITE_APP_URL,
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className={cn(
            "flex items-center space-x-3 px-0 hover:bg-transparent",
            className,
          )}
        >
          <MotionAvatar layout>
            <AvatarImage className="object-cover" />
            <AvatarFallback className="bg-emerald-100 text-zinc-900">
              {profile
                ? getInitials(profile.firstName, profile.lastName)
                : "..."}
            </AvatarFallback>
          </MotionAvatar>
          <div className="hidden md:block">
            <p className="text-md text-muted-foreground">
              {profile ? clsx(profile.firstName, profile.lastName) : "..."}
            </p>
          </div>
          <ChevronDownIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {profile ? clsx(profile.firstName, profile.lastName) : "..."}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {profile ? profile.username : "..."}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem>
            <Link
              className="flex w-full justify-between"
              to={`${RouteEnum.PROFILE}${isDemo ? "?demo=true" : ""}`}
            >
              {t("common_profile")}
              <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <a href={logoutUrl} className="cursor-pointer">
              {t("common_logout")}
              <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
            </a>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <div className="flex w-full items-center justify-between gap-2 px-2 py-1.5 text-sm">
            {t("common_theme")}
            <ThemeSwitch />
          </div>
          <div className="flex w-full items-center justify-between gap-2 px-2 py-1.5 text-sm">
            {t("common_language")}
            <LanguageSelector />
          </div>
          {IS_DEV && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <Link
                  className="flex w-full justify-between"
                  to={`${RouteEnum.DEV_SPACE}${isDemo ? "?demo=true" : ""}`}
                >
                  Dev Playground
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link
                  className="flex w-full justify-between"
                  to={`${RouteEnum.UI_DESIGN}${isDemo ? "?demo=true" : ""}`}
                >
                  UI & Design
                </Link>
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
