import { InfoCircledIcon } from "@radix-ui/react-icons";
import { Alert, AlertTitle, AlertDescription, type AlertVariants } from "../ui";
import { IconProps } from "@radix-ui/react-icons/dist/types";

interface InfoAlertProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  variant?: AlertVariants;
  // description?: string | React.ReactNode;
  children?: string | React.ReactNode;
  Icon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
}

export function InfoAlert({
  title,
  children,
  Icon = InfoCircledIcon,
  variant = "nxtLogGreen",
  ...props
}: InfoAlertProps) {
  return (
    <Alert variant={variant} {...props}>
      <Icon className="h-5 w-5" />
      {title && <AlertTitle className="font-bold">{title}</AlertTitle>}
      {children && <AlertDescription>{children}</AlertDescription>}
    </Alert>
  );
}
