import { BaseIcon, IconProps } from ".";

export function SolarUserIdLinear(props: IconProps) {
  return (
    <BaseIcon {...props}>
      <g fill="none" stroke="currentColor" strokeWidth="1.5">
        <circle cx="9" cy="9" r="2"></circle>
        <path d="M13 15c0 1.105 0 2-4 2s-4-.895-4-2s1.79-2 4-2s4 .895 4 2Z"></path>
        <path d="M2 12c0-3.771 0-5.657 1.172-6.828C4.343 4 6.229 4 10 4h4c3.771 0 5.657 0 6.828 1.172C22 6.343 22 8.229 22 12c0 3.771 0 5.657-1.172 6.828C19.657 20 17.771 20 14 20h-4c-3.771 0-5.657 0-6.828-1.172C2 17.657 2 15.771 2 12Z"></path>
        <path strokeLinecap="round" d="M19 12h-4m4-3h-5m5 6h-3"></path>
      </g>
    </BaseIcon>
  );
}
