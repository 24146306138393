import { api, authHeader } from "@/api";
import { useQuery } from "@tanstack/react-query";
import { useIsDemo } from "../useIsDemo";

interface GetMeResponse {
  uploadedFile: boolean;
  isAdmin: boolean;
}

async function getMe() {
  const response = await api.get<GetMeResponse>("/auth/me", {
    headers: { ...authHeader() },
  });
  return response.data;
}

export function useGetMe({ enabled }: { enabled: boolean }) {
  const isDemo = useIsDemo();
  return useQuery({
    queryKey: ["me"],
    queryFn: isDemo
      ? () => Promise.resolve({ uploadedFile: true, isAdmin: false })
      : getMe,
    staleTime: Infinity,
    enabled,
  });
}
