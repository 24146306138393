import { useAuth } from "@/api/auth";
import { Loader } from "@/assets/Loader";
import { useGetMe } from "@/hooks/queries";
import { RouteEnum } from "@/types";
import { Navigate, Outlet } from "react-router-dom";

export function UserStatusCheck() {
  const {
    keycloak: { subject },
  } = useAuth();
  const { data, isPending } = useGetMe({ enabled: Boolean(subject) });

  if (isPending) {
    return (
      <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center bg-background">
        <Loader className="h-1/4" />
      </div>
    );
  }

  if (data?.uploadedFile) {
    return <Navigate to={RouteEnum.DASHBOARD} />;
  }

  return <Outlet />;
}
