import {
  SocketEvent,
  StatusUpdateEventType,
} from "@/context-providers/SocketProvider/types";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSocketEvent } from "./useSocketEvent";

export function useRefetchOnStatusUpdate() {
  const queryClient = useQueryClient();

  const handleEvent = useCallback(
    ({ status }: StatusUpdateEventType) => {
      if (!status) {
        return;
      }
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["/shipments/files"],
          refetchType: "all",
        }),
        queryClient.invalidateQueries({
          queryKey: ["shipments"],
          refetchType: "all",
        }),
        queryClient.invalidateQueries({
          queryKey: ["supplyChain"],
          refetchType: "all",
        }),
      ]).catch(console.error);
    },
    [queryClient],
  );

  useSocketEvent(SocketEvent.DELETE_STATUS_UPDATE, handleEvent);
}
