import { Link } from "react-router-dom";
import { Button } from "../ui";
import { Cross1Icon } from "@radix-ui/react-icons";
import { PreferencesDialog } from "./PreferencesDialog";
import { useCookieConsent } from "./useCookieConsent";
import { RouteEnum } from "@/types";

export function CookieConsentBanner() {
  const { acceptAllCookies, rejectAllCookies, closeBanner, showBanner } =
    useCookieConsent();

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-0 z-[101] flex w-full flex-col items-center gap-4 border-t bg-background/80 p-4 pr-10 backdrop-blur md:flex-row">
      <p className="text-sm font-light text-foreground">
        Welcome to NxtLog! To provide you with the best experience, we use
        cookies and similar technologies to enhance site navigation, analyze
        site usage, and personalize your experience. By clicking &quot;Accept
        All Cookies&quot;, you agree to the storing of cookies on your device.
        You can manage your cookie preferences or find out more by visiting our{" "}
        <Link to={RouteEnum.COOKIE_POLICY} className="underline">
          Cookie Policy
        </Link>{" "}
        .
      </p>
      <div className="flex flex-wrap justify-center gap-2 md:flex-nowrap">
        <Button
          className="whitespace-nowrap"
          size="sm"
          onClick={() => {
            acceptAllCookies();
            closeBanner();
          }}
        >
          Accept All Cookies
        </Button>
        <PreferencesDialog />
        <Button
          className="whitespace-nowrap"
          size="sm"
          variant="outline"
          onClick={() => {
            rejectAllCookies();
            closeBanner();
          }}
        >
          Reject Non-Essential Cookies
        </Button>
      </div>
      <Button
        size="icon"
        variant="outline"
        className="absolute right-1 top-1 h-6 w-6 rounded-full"
        onClick={closeBanner}
      >
        <Cross1Icon className="h-3.5 w-3.5" />
      </Button>
    </div>
  );
}
