import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import { ScrollArea } from "../ui";
import React from "react";
// import { useLocation } from "react-router-dom";

export function ContentWithSidebar({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <motion.div
      className="grid grid-cols-4 gap-6 py-6 [&>div:nth-child(1)]:col-span-4 lg:[&>div:nth-child(1)]:col-span-3 [&>div:nth-child(2)]:hidden lg:[&>div:nth-child(2)]:col-span-1 lg:[&>div:nth-child(2)]:block"
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}
export function ContentWithoutSidebar({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <motion.div
      className={cn("w-full pb-9 pt-3", className)}
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}

interface HeaderContentProps extends React.ComponentPropsWithoutRef<"div"> {
  children: React.ReactNode;
}

export function HeaderContent({
  children,
  className,
  ...props
}: HeaderContentProps) {
  return (
    <div
      className={cn(
        "sticky top-0 z-[10] flex flex-col items-center justify-between gap-2 border-b bg-gray-50/70 py-2 backdrop-blur dark:bg-zinc-900/70 md:flex-row lg:py-4",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export const MainContent = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    className?: string;
  }
>(({ children, className }, ref) => (
  <div ref={ref} className={cn("relative w-full space-y-6", className)}>
    {children}
  </div>
));

MainContent.displayName = "MainContent";

export function SideBarContent({ children }: { children: React.ReactNode }) {
  return (
    <div className="space-y-4 divide-y rounded bg-muted p-6 [&>*:not(:first-child)]:pt-4">
      {children}
    </div>
  );
}

export function PageScrollContainer({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <ScrollArea className="h-full">
      {/* <div className={cn("max-w-[100vw] p-2 lg:p-6", className)}> */}
      <div className={cn("max-w-[100vw] px-2 lg:px-6", className)}>
        {children}
      </div>
    </ScrollArea>
  );
}
