/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "../ui";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { InfoAlert } from ".";

export function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: () => void;
}) {
  return (
    <div className="grid h-full w-full place-content-center">
      <InfoAlert
        className="w-auto"
        variant="nxtLogRed"
        title="Something went wrong"
        Icon={ExclamationTriangleIcon}
      >
        <pre className="whitespace-normal py-4 text-2xs font-light text-gray-500">
          {JSON.stringify(error, null, 2)}
        </pre>
      </InfoAlert>
      <Button
        variant="outline"
        size="sm"
        className="mt-4"
        onClick={resetErrorBoundary}
      >
        Try again ?
      </Button>
    </div>
  );
}
