import { toast } from "@/components/ui";
import { queryClient } from "@/lib/queryClient";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import Keycloak from "keycloak-js";

const VITE_KEYCLOAK_URL = import.meta.env.VITE_KEYCLOAK_URL;
const VITE_KEYCLOAK_REALM = import.meta.env.VITE_KEYCLOAK_REALM;
const VITE_KEYCLOAK_CLIENT_ID = import.meta.env.VITE_KEYCLOAK_CLIENT_ID;

const keycloakInstance = new Keycloak({
  url: VITE_KEYCLOAK_URL,
  realm: VITE_KEYCLOAK_REALM,
  clientId: VITE_KEYCLOAK_CLIENT_ID,
});

keycloakInstance.onAuthLogout = () => {
  window.$chatwoot.reset();
  queryClient
    .invalidateQueries({
      queryKey: ["me"],
      refetchType: "none",
    })
    .catch((error) => {
      console.error(error);
    });
  toast({
    description: (
      <div className="flex items-center gap-3">
        <InfoCircledIcon className="h-5 w-5 shrink-0" />
        <span className="font-medium">
          Session expired, please log in again
        </span>
      </div>
    ),
    variant: "warning",
  });
};

const initKeycloak = () =>
  keycloakInstance.init({
    onLoad: "check-sso",
    checkLoginIframe: false,
  });

export { keycloakInstance, initKeycloak };
