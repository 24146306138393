import { SolarWiFiRouterMinimalisticLinear } from "@/assets/icons";
import { useToast } from "@/components/ui";
import { useEffect, useRef, useState } from "react";
// import { useIsMounted } from "usehooks-ts";

export function useIsOffline() {
  const { toast } = useToast();
  const isMountedRef = useRef(false);
  // const isMounted = useIsMounted();
  // Set up state
  const [isOffline, setIsOffline] = useState(() => false);

  // Set up handlers
  function onOffline() {
    setIsOffline(true);
  }

  function onOnline() {
    setIsOffline(false);
  }

  // Run this on mount only - due to []
  useEffect(() => {
    // Change state whenver these occur..
    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);

    // Return a function removing these handlers on unmount
    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
    };
  }, []);

  useEffect(() => {
    if (isOffline) {
      toast({
        // title: "No Internet Connection",
        description: (
          <div className="flex items-center gap-x-6 text-sm">
            <SolarWiFiRouterMinimalisticLinear className="h-8 w-8 shrink-0" />
            <span>
              It looks like you&apos;re offline. Please check your connection.
            </span>
          </div>
        ),
        variant: "warning",
      });
    }
    if (isMountedRef.current) {
      if (!isOffline) {
        toast({
          description: (
            <div className="flex items-center gap-x-6 text-sm">
              <SolarWiFiRouterMinimalisticLinear className="h-8 w-8 shrink-0" />
              <span>You are now connected to the internet.</span>
            </div>
          ),
          variant: "success",
        });
      }
    } else {
      isMountedRef.current = true;
    }
  }, [isOffline, toast]);
}
