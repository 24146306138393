import { useAuth } from "@/api/auth";
import { ThemeSwitch } from "@/components/layouts/NavigationLayout/components";
import { LanguageSelector } from "@/components/shared";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  buttonVariants,
} from "@/components/ui";
import { useIsDemo } from "@/hooks";
import { getInitials } from "@/lib/utils";
import { RouteEnum } from "@/types";
import { ExitIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export function ProfileDetails() {
  const isDemo = useIsDemo();
  const { t } = useTranslation();
  const { profile, keycloak } = useAuth();
  const logoutUrl = keycloak.createLogoutUrl({
    redirectUri: import.meta.env.VITE_APP_URL,
  });
  return (
    <>
      <div className="flex flex-col gap-y-3">
        <div className="flex flex-col items-center gap-6 sm:flex-row sm:items-start">
          <Avatar className="h-24 w-24">
            <AvatarImage className="object-cover" />
            <AvatarFallback className="bg-emerald-100 text-3xl text-zinc-900">
              {profile
                ? getInitials(profile.firstName, profile.lastName)
                : "..."}
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col gap-y-2">
            <p className="text-2xl font-semibold">
              {clsx(profile?.firstName, profile?.lastName)}
            </p>
            <p>{profile?.username}</p>
            <div className="flex items-center">
              {t("common_language")}: <LanguageSelector />
            </div>
            <div className="flex items-center gap-4">
              {t("common_theme")}: <ThemeSwitch />
            </div>
            <div className="mt-6 flex gap-3">
              <a
                className={buttonVariants({ variant: "outline" })}
                href={logoutUrl}
              >
                <ExitIcon className="mr-3 h-4 w-4 rotate-180" />{" "}
                {t("common_logout")}
              </a>
              <Button
                disabled={isDemo}
                onClick={() =>
                  keycloak.login({
                    action: "UPDATE_PASSWORD",
                    redirectUri: `${import.meta.env.VITE_APP_URL}${
                      RouteEnum.PROFILE
                    }/password-updated?sendEmail=true`,
                  })
                }
              >
                {t("common_updatePassword")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
