import { BaseIcon, IconProps } from ".";

export function SolarSun2Linear(props: IconProps) {
  return (
    <BaseIcon {...props}>
      <g fill="none" stroke="currentColor" strokeWidth="1.5">
        <circle cx="12" cy="12" r="5"></circle>
        <path
          strokeLinecap="round"
          d="M12 2v2m0 16v2M4 12H2m20 0h-2m-.222-7.777l-2.222 2.031M4.222 4.223l2.222 2.031m0 11.302l-2.222 2.222m15.556-.001l-2.222-2.222"
        ></path>
      </g>
    </BaseIcon>
  );
}
