import { Card, CardContent, CardHeader } from "@/components/ui";
import { TriangleUpIcon } from "@radix-ui/react-icons";
import { motion } from "framer-motion";
import { InfoAlert, InfoHoverButton, MediumHeading } from "..";
import { upperFirst } from "lodash-es";
// Mock data
import data from "./mock-data/data_score.json";
import { NoDataAlert } from ".";
import NoChartSvg from "@/assets/NoChartSvg";
import { useIsDemo } from "@/hooks";

const AnimatedTriangleUpIcon = motion(TriangleUpIcon);

export interface DatascoreProps {
  className?: string;
}
export function Datascore({
  className = "w-full rounded-sm shadow-none",
}: DatascoreProps) {
  const isDemo = useIsDemo();
  const datascoreGraph = (
    <div>
      <div className="grid w-full grid-cols-3 gap-0.5 overflow-hidden rounded-xl">
        <div className="h-8 bg-amber-200" />
        <div className="h-8 bg-lime-300" />
        <div className="h-8 bg-primary" />
      </div>
      <AnimatedTriangleUpIcon
        className="h-7 w-7 -translate-x-3.5"
        animate={{ marginLeft: `${data.value}%` }}
        transition={{ duration: 1, type: "linear" }}
      />
    </div>
  );

  const variance = data.value - data.avgValue;

  return (
    <Card className={className}>
      <CardHeader className="p-2 md:p-6">
        <div className="flex w-full items-center justify-between">
          <MediumHeading>{data.title}</MediumHeading>
          <InfoHoverButton />
        </div>
      </CardHeader>
      <CardContent className="flex flex-1 flex-col justify-between gap-6 p-2 md:p-6">
        {isDemo ? (
          <>
            {datascoreGraph}
            <div className="flex items-center gap-3">
              <span className="font-bold">{upperFirst(data.dataRating)}</span>
              <span>|</span>
              <span>{`${variance}% ${
                variance > 0 ? "more" : "less"
              } than average`}</span>
            </div>
            <InfoAlert variant="default">
              {`To enhance the accuracy of your data and improve your data score, we
          recommend the following actions: ${data.actionItems.join(", ")}.`}
            </InfoAlert>
          </>
        ) : (
          <>
            <NoChartSvg className="h-[150px] opacity-20" />
            <NoDataAlert />
          </>
        )}
      </CardContent>
    </Card>
  );
}
